class Cycle {
    constructor() {
        this.refIndex = 1;
        this.ref2obj = new Map();
        this.obj2ref = new Map();
    }
    setObject(obj) {
        this.obj2ref.set(obj, this.refIndex);
        return (this.refIndex++).toString();
    }
}
export class RefHandler {
    constructor() {
        this.keyWord = ["$id", "$ref"];
        this.cycle = new Cycle();
    }
    clean() {
        this.cycle = new Cycle();
    }
    deserializationGetObject(json) {
        if (json === null || json === void 0 ? void 0 : json.hasOwnProperty("$ref")) {
            const ref = parseInt(json.$ref, 10);
            if (!this.cycle.ref2obj.has(ref)) {
                throw new Error("Reference found before its definition");
            }
            return this.cycle.ref2obj.get(ref);
        }
        return undefined;
    }
    deserializationRegisterObject(json, obj) {
        if (json === null || json === void 0 ? void 0 : json.hasOwnProperty("$id")) {
            this.cycle.ref2obj.set(parseInt(json.$id, 10), obj);
        }
    }
    serializationSetID(json, obj) {
        json.$id = this.cycle.setObject(obj);
    }
    serializationSetRef(json, obj) {
        json.$ref = this.cycle.obj2ref.get(obj).toString();
    }
}
let refHandlerStore = new RefHandler();
export function getRefHandler() {
    return refHandlerStore;
}
export function setRefHandler(refHandler) {
    refHandlerStore = refHandler;
}
