/**
 * Enum that contains back help center types
 */
export enum HelpCenterTypes {
  InvoicesAndPayments = 'invoice-and-payments',
  TechnicalSupport = 'technical-support',
  Degrees = 'degrees',
  Tutorship = 'tutorship'
}

export const helpCenterTypesValues = [
  HelpCenterTypes.InvoicesAndPayments,
  HelpCenterTypes.TechnicalSupport,
  HelpCenterTypes.Degrees,
  HelpCenterTypes.Tutorship
];

export enum TicketsStatusTypes {
  Resolved = 2,
  Pending = 1
}
