import { EnvironmentInterface } from '../definitions/environment.interface';
import { commonEnv } from './environment.common';

const env = {
  name: 'dev',
  production: false,
  apiPath: 'api',
  apiSecurityDomain: 'eurodev.bi4dev.com',
  httpSecure: true,
  universitySecurityApiDomain: 'root.dev.educa.university',
  backofficeDomain: 'https://dev.backoffice.mylxp.ai',
  baseAWSUrl: 'https://euapps-dev-uploads.s3.eu-west-1.amazonaws.com',

  // Sentry settings
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0,
  showDialog: false
};

export const environment: EnvironmentInterface = Object.assign(commonEnv, env);
