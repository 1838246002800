import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { RestItemList } from './rest-item';

export class Province {
  /**
   * Province ID
   */
  @autoserializeAs(() => Number) _id!: number;

  /**
   * Province name
   */
  @autoserializeAs(() => String) text!: string;

  constructor(_id: number, text: string) {
    this._id = _id;
    this.text = text;
  }
}

/**
 * Class that represents a Country list response
 */
export class ProvinceList extends RestItemList<Province> {
  /**
   * Course list
   */
  @autoserializeAsArray(() => Province) items: Province[];

  /**
   * Number of students
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Province[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
