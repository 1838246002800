import { NoOp, } from "./string_transforms";
import { ArrayHandling, InstantiationMethod, IsReference, noDefaultValueSymbole, } from "./types";
import { isPrimitiveAnonymousType, } from "./utils";
class TypeMap extends Map {
    get(key) {
        return super.get(key);
    }
    set(key, value) {
        return super.set(key, value);
    }
}
const TypeMapProp = new TypeMap();
const TypeMapClass = new TypeMap();
export class PropMetaData {
    constructor(keyName) {
        this.keyName = keyName;
        this.serializedKey = "";
        this.deserializedKey = "";
        this.deserializedType = () => Function;
        this.serializedType = () => Function;
        this.serializedKeyType = () => Function;
        this.deserializedKeyType = () => Function;
        this.flags = 0;
        this.bitMaskSerialize = Number.MAX_SAFE_INTEGER;
        this.emitDefaultValue = true;
        this.defaultValue = noDefaultValueSymbole;
        this.arrayHandling = ArrayHandling.Into;
    }
    static hasKeyName(metadataArray, key) {
        for (const metadata of metadataArray) {
            if (metadata.keyName === key) {
                return true;
            }
        }
        return false;
    }
    static isMap(flags, target) {
        return (flags & 32768) !== 0;
    }
    static clone(data) {
        const metadata = new PropMetaData(data.keyName);
        metadata.deserializedKey = data.deserializedKey;
        metadata.serializedKey = data.serializedKey;
        metadata.serializedType = data.serializedType;
        metadata.deserializedType = data.deserializedType;
        metadata.serializedKeyType = data.serializedKeyType;
        metadata.deserializedKeyType = data.deserializedKeyType;
        metadata.serializedValueType = data.serializedValueType;
        metadata.deserializedValueType = data.deserializedValueType;
        metadata.flags = data.flags;
        metadata.bitMaskSerialize = data.bitMaskSerialize;
        metadata.emitDefaultValue = data.emitDefaultValue;
        metadata.defaultValue = data.defaultValue;
        metadata.arrayHandling = data.arrayHandling;
        return metadata;
    }
    static getMetaData(target, keyName) {
        let metaDataList = TypeMapProp.get(target);
        if (metaDataList === undefined) {
            metaDataList = [];
            TypeMapProp.set(target, metaDataList);
        }
        for (const metadata of metaDataList) {
            if (metadata.keyName === keyName) {
                return metadata;
            }
        }
        metaDataList.push(new PropMetaData(keyName));
        return metaDataList[metaDataList.length - 1];
    }
    static inheritMetaData(parentType, childType) {
        let childMetaData = TypeMapProp.get(childType) || [];
        const parentMetaDataCloned = (TypeMapProp.get(parentType) || [])
            .filter((elt) => !PropMetaData.hasKeyName(childMetaData, elt.keyName))
            .map((elt) => PropMetaData.clone(elt));
        childMetaData = parentMetaDataCloned.concat(childMetaData);
        TypeMapProp.set(childType, childMetaData);
    }
    static getMetaDataForType(type) {
        var _a;
        if (type !== null && type !== undefined) {
            return (_a = TypeMapProp.get(type)) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }
    getSerializedKey() {
        if (this.serializedKey === this.keyName) {
            return PropMetaData.serializeKeyTransform(this.keyName);
        }
        return this.serializedKey ? this.serializedKey : this.keyName;
    }
    getDeserializedKey() {
        if (this.deserializedKey === this.keyName) {
            return PropMetaData.deserializeKeyTransform(this.keyName);
        }
        return PropMetaData.deserializeKeyTransform(this.deserializedKey ? this.deserializedKey : this.keyName);
    }
}
PropMetaData.serializeKeyTransform = NoOp;
PropMetaData.deserializeKeyTransform = NoOp;
PropMetaData.deserializeInstantiationMethod = InstantiationMethod.New;
export function isDefaultValue(metadata, val) {
    if (metadata.emitDefaultValue === false) {
        const defVal = getDefaultValue(metadata, val);
        return defVal === val;
    }
    return false;
}
export class ClassMetaData {
    constructor() {
        this.isReference = IsReference.Default;
    }
    static getMetaDataOrDefault(target) {
        if (target === undefined) {
            return new ClassMetaData();
        }
        else {
            let classMetaData = TypeMapClass.get(target);
            if (classMetaData === undefined) {
                classMetaData = new ClassMetaData();
                TypeMapClass.set(target, classMetaData);
            }
            return classMetaData;
        }
    }
}
ClassMetaData.refCycleDetection = false;
export function getDefaultValue(metadata, val) {
    if (metadata.emitDefaultValue === false) {
        if (metadata.defaultValue !== noDefaultValueSymbole) {
            return metadata.defaultValue;
        }
        else if (isPrimitiveAnonymousType(metadata.serializedType) ||
            (val !== undefined && val !== null && val !== Object(val))) {
            return new (metadata.serializedType())().valueOf();
        }
        else {
            return null;
        }
    }
    else {
        return undefined;
    }
}
