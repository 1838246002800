export enum LoginMethod {
  Google = 'google',
  Facebook = 'facebook',
  Euroinnova = 'euroinnova',
  LinkedIn = 'linkedin'
}

export enum CourseSlidersNames {
  Popular = 'popular',
  Included = 'included',
  BestRated = 'best-rated',
  New = 'new',
  Area = 'area',
  Recommended = 'recommended',
  RelatedMasters = 'related-masters',
  RelatedCourses = 'related-courses'
}

export enum EnrolmentStatus {
  Active = 'Activa',
  Cancelled = 'Anulada',
  Finalized = 'Finalizada',
  Convalidated = 'Convalidada',
  ReturnLow = 'Devuelta - Baja',
  Waiting = 'En espera',
  Abandonment = 'Abandono',
  Prorogued = 'Prórroga',
  ExtensionOfTime = 'Ampliación Plazo',
  Interrupted = 'Interrumpida',
  PreRegistration = 'Prematrícula',
  Processing = 'Processing'
}

export enum EnrolmentType {
  Legacy = 'legacy',
  Current = 'current'
}

export enum DocumentationType {
  DNI = 'DNI',
  Curriculum = 'Curriculum Vitae',
  Others = 'Otros'
}

export enum CommunicationDirections {
  Adviser = 1,
  User = 2
}

export enum HelpCenterTypes {
  InvoicesAndPayments = 'invoice-and-payments',
  TechnicalSupport = 'technical-support',
  Degrees = 'degrees',
  Tutorship = 'tutorship'
}

export enum BillingType {
  Stripe = 'stripe',
  Innopay = 'innopay'
}

export enum CourseTypeDurations {
  MONTHS = 'months',
  DAYS = 'days',
  YEARS = 'years',
  HOURS = 'hours',
  YEAR = 'year',
  WEEKS = 'weeks',
  MINUTES = 'minutes'
}

export enum SubscriptionType {
  Yearly = 'yearly',
  Monthly = 'monthly',
  SixMonth = 'six_month'
}

export enum EnrolmentSource {
  INNOTUTOR = 'Innotutor',
  BACKOFFICE = 'Backoffice',
  INTERN = 'Intern'
}
