import { autoserializeAsArray } from 'dcerialize';

/**
 * Class that represents the liked courses
 */
export class SavedCourses {
  /**
   * liked courses
   */
  @autoserializeAsArray(() => Number) savedCourses: number[] = [];
}
