import { cycleBreaking, } from "./cycle";
import { ClassMetaData, isDefaultValue, PropMetaData, } from "./meta_data";
import { getRefHandler, } from "./ref";
import { TypeString, } from "./runtime_typing";
import { isItAnArrayInternal, IsReference, } from "./types";
import { DowncastPrimitive, isPrimitiveType, } from "./utils";
let serializeBitMaskPrivate = Number.MAX_SAFE_INTEGER;
export function SelectiveSerialization(bitMask = Number.MAX_SAFE_INTEGER) {
    serializeBitMaskPrivate = bitMask;
}
export function SerializeObjectMapInternal(source, type) {
    if (source === null) {
        return null;
    }
    const target = {};
    const keys = Object.keys(source);
    const isReference = ClassMetaData.getMetaDataOrDefault(source.constructor).isReference;
    if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
        isReference === IsReference.True) {
        if (cycleBreaking.seen(source)) {
            getRefHandler().serializationSetRef(target, source);
            return target;
        }
        else {
            getRefHandler().serializationSetID(target, source);
        }
    }
    if (isItAnArrayInternal(type)) {
        if (TypeString.getRuntimeTyping()) {
            target.$type = TypeString.getStringFromType(source.constructor);
        }
    }
    else {
        if (TypeString.getRuntimeTyping() && !isPrimitiveType(type())) {
            target.$type = TypeString.getStringFromType(source.constructor);
        }
        for (const key of keys) {
            const value = source[key];
            if (value !== undefined) {
                target[PropMetaData.serializeKeyTransform(key)] = SerializeInternal(value, type);
            }
        }
    }
    return target;
}
export function SerializeMapInternal(source, keyType, valueType) {
    if (source === null) {
        return null;
    }
    const target = {};
    const keys = source.keys();
    const isReference = ClassMetaData.getMetaDataOrDefault(source.constructor).isReference;
    if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
        isReference === IsReference.True) {
        if (cycleBreaking.seen(source)) {
            getRefHandler().serializationSetRef(target, source);
            return target;
        }
        else {
            getRefHandler().serializationSetID(target, source);
        }
    }
    if (TypeString.getRuntimeTyping()) {
        target.$type = TypeString.getStringFromType(source.constructor);
    }
    for (const key of keys) {
        const value = source.get(key);
        if (value !== undefined) {
            const keyTypeF = keyType();
            function isStringConstructor(val) {
                return keyTypeF === String;
            }
            const targetKey = keyTypeF(isStringConstructor(key) ? PropMetaData.serializeKeyTransform(key) : key);
            const targetValue = SerializeInternal(value, valueType);
            target[targetKey] = targetValue;
        }
    }
    return target;
}
export function SerializeArrayInternal(source, type) {
    if (source === null) {
        return null;
    }
    const returnValue = new Array(source.length);
    for (let i = 0; i < source.length; i++) {
        returnValue[i] = SerializeInternal(source[i], type);
    }
    return returnValue;
}
export function SerializeSetInternal(source, type) {
    if (source === null) {
        return null;
    }
    return SerializeArrayInternal(Array.from(source.values()), type);
}
export function SerializePrimitiveInternal(source, type) {
    if (source === null) {
        return null;
    }
    function isObjectPrimitive(value) {
        return source instanceof Object;
    }
    const primitiveSource = isObjectPrimitive(source) ? DowncastPrimitive(source) : source;
    if (type() === String) {
        return String(primitiveSource);
    }
    if (type() === Boolean) {
        return Boolean(primitiveSource);
    }
    if (type() === Number) {
        const val = Number(primitiveSource);
        return isNaN(val) && !Number.isNaN(val) ?
            null : val;
    }
    if (type() === Date) {
        return primitiveSource.valueOf();
    }
    if (type() === RegExp) {
        return primitiveSource.toString();
    }
    return primitiveSource.toString();
}
export function SerializeJSONInternal(source, transformKeys = true) {
    if (source === null) {
        return null;
    }
    if (Array.isArray(source)) {
        const array = new Array(source.length);
        for (let i = 0; i < source.length; i++) {
            array[i] = SerializeJSONInternal(source[i], transformKeys);
        }
        return array;
    }
    const type = typeof source;
    if (type === "object") {
        if (source instanceof Date || source instanceof RegExp) {
            return source.toString();
        }
        else {
            const returnValue = {};
            const keys = Object.keys(source);
            for (const key of keys) {
                const value = source[key];
                if (value !== undefined) {
                    const returnValueKey = transformKeys
                        ? PropMetaData.serializeKeyTransform(key)
                        : key;
                    returnValue[returnValueKey] = SerializeJSONInternal(value, transformKeys);
                }
            }
            return returnValue;
        }
    }
    else if (type === "function") {
        return null;
    }
    return source;
}
export function SerializeInternal(instance, type) {
    if (instance === undefined || instance === null) {
        return null;
    }
    const target = {};
    if (isItAnArrayInternal(type)) {
        return SerializeArrayInternal(instance, type.type);
    }
    else {
        if (TypeString.getRuntimeTyping() && !isPrimitiveType(type())) {
            if (!instance.constructor) {
                throw new Error("Can not guess the type of the class serialized");
            }
            target.$type = TypeString.getStringFromType(instance.constructor);
            type = () => instance.constructor;
        }
        const metadataList = PropMetaData.getMetaDataForType(type());
        if (metadataList === null) {
            if (isPrimitiveType(type())) {
                return SerializePrimitiveInternal(instance, type);
            }
            else {
                return target;
            }
        }
        const isReference = ClassMetaData.getMetaDataOrDefault(instance.constructor).isReference;
        if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
            isReference === IsReference.True) {
            if (cycleBreaking.seen(instance)) {
                getRefHandler().serializationSetRef(target, instance);
                return target;
            }
            else {
                getRefHandler().serializationSetID(target, instance);
            }
        }
        for (const metadata of metadataList) {
            if (!(metadata.bitMaskSerialize & serializeBitMaskPrivate)) {
                continue;
            }
            if (metadata.serializedKey === null) {
                continue;
            }
            const source = instance[metadata.keyName];
            if (source === undefined) {
                continue;
            }
            const keyName = metadata.getSerializedKey();
            const flags = metadata.flags;
            if ((flags & 65536) !== 0) {
                const val = SerializeMapInternal(source, metadata.serializedKeyType, metadata.serializedValueType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 64) !== 0) {
                const val = SerializeObjectMapInternal(source, metadata.serializedType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 131072) !== 0) {
                const val = SerializeSetInternal(source, metadata.serializedKeyType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 16) !== 0) {
                const val = SerializeArrayInternal(source, metadata.serializedKeyType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 4) !== 0) {
                const val = SerializePrimitiveInternal(source, metadata.serializedType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 16384) !== 0) {
                const val = SerializeInternal(source, metadata.serializedType);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 256) !== 0) {
                const val = SerializeJSONInternal(source, (flags & 1024) !== 0);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
            else if ((flags & 4096) !== 0) {
                const val = metadata.serializedType(source);
                if (isDefaultValue(metadata, source)) {
                    continue;
                }
                target[keyName] = val;
            }
        }
        const callback = type().onSerialized;
        if (typeof callback === "function") {
            const value = callback(target, instance);
            if (value !== undefined && value !== null) {
                return value;
            }
        }
        return target;
    }
}
