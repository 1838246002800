export var InstantiationMethod;
(function (InstantiationMethod) {
    InstantiationMethod[InstantiationMethod["None"] = 0] = "None";
    InstantiationMethod[InstantiationMethod["New"] = 1] = "New";
    InstantiationMethod[InstantiationMethod["ObjectCreate"] = 2] = "ObjectCreate";
})(InstantiationMethod || (InstantiationMethod = {}));
export var ArrayHandling;
(function (ArrayHandling) {
    ArrayHandling[ArrayHandling["Into"] = 0] = "Into";
    ArrayHandling[ArrayHandling["ConcatAtTheEnd"] = 1] = "ConcatAtTheEnd";
    ArrayHandling[ArrayHandling["New"] = 2] = "New";
})(ArrayHandling || (ArrayHandling = {}));
export class ItIsAnArrayInternal {
    constructor(type, ctor = (() => Array), handling = ArrayHandling.Into) {
        this.type = type;
        this.ctor = ctor;
        this.handling = handling;
    }
}
export function isItAnArrayInternal(arg) {
    return arg instanceof ItIsAnArrayInternal;
}
export const noDefaultValueSymbole = Symbol("No default value");
export var IsReference;
(function (IsReference) {
    IsReference[IsReference["Default"] = 0] = "Default";
    IsReference[IsReference["True"] = 1] = "True";
    IsReference[IsReference["False"] = 2] = "False";
})(IsReference || (IsReference = {}));
