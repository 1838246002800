import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { EnrolmentStatus } from '../definitions/enrolment.enum';
import { finishedStatuses, inProgressStatuses } from './enrolment-with-course';
import { RestItemList } from './rest-item';

/**
 * Class that represents a course certificate
 */
export class Certificate {
  /**
   * Related course name
   */
  @autoserializeAs(() => String) courseName: string;

  /**
   * Related enrolment ID
   */
  @autoserializeAs(() => String) enrolmentId: string;

  /**
   * Related course status
   */
  @autoserializeAs(() => String) enrolmentStatus: EnrolmentStatus;

  /**
   * Entity name
   */
  @autoserializeAs(() => String) issuingCompany: string;

  /**
   * Date when certificate was sent
   */
  @autoserializeAs(() => Date) envoyDate: Date;

  /**
   * Date when certificate was issued
   */
  @autoserializeAs(() => Date) scheduledDate: Date;

  /**
   * Date when certificate was received (apostille)
   */
  @autoserializeAs(() => Date) receivedApostilleDate: Date;

  /**
   * Alfresco ID to download the degree
   */
  @autoserializeAs(() => String) alfrescoId: string;

  /**
   * Whether the associated course is free
   */
  @autoserializeAs(() => Boolean) freeCourseEnrolment: boolean;

  @autoserializeAs(() => String) certificateId: string;

  @autoserializeAs(() => Boolean) fromOpenEnrolment: boolean;

  /**
   * Innotutor enrolment state
   */
  @autoserializeAs(() => Number) innotutorEnrolmentState?: number;

  /**
   * Innotutor course type id
   */
  @autoserializeAs(() => Number) innotutorCourseTypeId?: number;

  constructor(
    courseName: string,
    enrolmentId: string,
    enrolmentStatus: EnrolmentStatus,
    issuingCompany: string,
    envoyDate: Date,
    scheduledDate: Date,
    receivedApostilleDate: Date,
    alfrescoId: string,
    freeCourseEnrolment: boolean,
    certificateId: string,
    fromOpenEnrolment: boolean,
    innotutorEnrolmentState?: number,
    innotutorCourseTypeId?: number
  ) {
    this.courseName = courseName;
    this.enrolmentId = enrolmentId;
    this.enrolmentStatus = enrolmentStatus;
    this.issuingCompany = issuingCompany;
    this.envoyDate = envoyDate;
    this.scheduledDate = scheduledDate;
    this.receivedApostilleDate = receivedApostilleDate;
    this.alfrescoId = alfrescoId;
    this.freeCourseEnrolment = freeCourseEnrolment;
    this.certificateId = certificateId;
    this.fromOpenEnrolment = fromOpenEnrolment;
    this.innotutorEnrolmentState = innotutorEnrolmentState;
    this.innotutorCourseTypeId = innotutorCourseTypeId;
  }

  getEnrolmentId(): string {
    return this.enrolmentId;
  }

  /**
   * Whether the certificate is finalized
   * Innotutor course type id 98 means that the course is MOOC
   */
  isFinalized(): boolean {
    return this.alfrescoId === null && this.innotutorCourseTypeId !== 98 && this.courseIsFinished();
  }

  /**
   * Method to know if the associated course is finished
   */
  courseIsFinished(): boolean {
    return finishedStatuses.includes(this.enrolmentStatus);
  }

  /**
   * Whether is possible to modify the user data before download the certificate
   */
  allowModifyUserBeforeDownload(): boolean {
    return !this.alfrescoId && !this.certificateId && !this.scheduledDate;
  }

  /**
   * Whether the associated course is in progress
   */
  courseIsInProgress(): boolean {
    return inProgressStatuses.includes(this.enrolmentStatus);
  }

  /**
   * Whether the certificate is in progress
   */
  isInProcess(): boolean {
    return (
      this.scheduledDate === null &&
      this.envoyDate === null &&
      this.receivedApostilleDate === null &&
      !this.freeCourseEnrolment
    );
  }

  /**
   * Method to now if the certificate is issued
   */
  isIssued(): boolean {
    return this.scheduledDate !== null;
  }

  isSent(): boolean {
    return this.envoyDate !== null;
  }

  isReceived(): boolean {
    return this.receivedApostilleDate !== null;
  }

  /**
   * Method to know if the certificate is ready to download
   */
  isDegreeAvailable(): boolean {
    return this.courseIsFinished() && this.isIssued() && this.alfrescoId !== null;
  }
}

export class CertificateList extends RestItemList<Certificate> {
  /**
   * Certificate list
   */
  @autoserializeAsArray(() => Certificate) items: Certificate[];

  /**
   * Number of items
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Certificate[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
