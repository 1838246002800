import { autoserializeAs } from 'dcerialize';

/**
 * Helper to create a different filters depend on the list
 */
export abstract class TableFilter<T> {
  /**
   * List object
   */
  abstract filter: T;
  /**
   * Page limit
   */
  @autoserializeAs(() => Number) limit: number;
  /**
   * Page number
   */
  @autoserializeAs(() => Number) page: number;
  /**
   * Query
   */
  @autoserializeAs(() => String) query: string;
  /**
   * Sort by
   */
  @autoserializeAs(() => String) sort: string;

  protected constructor(limit: number, page: number, query: string, sort: string) {
    this.limit = limit;
    this.page = page;
    this.query = query;
    this.sort = sort;
  }
}
