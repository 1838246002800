import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { RestItemList } from './rest-item';

/**
 * Webinar CRUD class
 */
export class WebinarEnrolment {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id?: number;

  /**
   * Webinar Id
   */
  @autoserializeAs(() => Number) webinarId!: number;

  /**
   * User Id
   */
  @autoserializeAs(() => Number) userId!: number;

  constructor(_id: number, webinarId: number, userId: number) {
    this._id = _id;
    this.webinarId = webinarId;
    this.userId = userId;
  }
}

export class WebinarEnrolmentList extends RestItemList<WebinarEnrolment> {
  /**
   * Webinar list
   */
  @autoserializeAsArray(() => WebinarEnrolment) items: WebinarEnrolment[];

  /**
   * Number of articles
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: WebinarEnrolment[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
