import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { Country } from './country';
import { Province } from './province';
import { RestItemList } from './rest-item';

/**
 * Address User CRUD class
 */
export class UserAddress {
  /**
   * Street name
   */
  @autoserializeAs(() => String) street?: string;

  /**
   * Home type
   */
  @autoserializeAs(() => String) homeType?: string;

  /**
   * Postal Code
   */
  @autoserializeAs(() => String) postalCode?: string;

  /**
   * City
   */
  @autoserializeAs(() => String) city?: string;

  /**
   * District
   */
  @autoserializeAs(() => Province) district?: Province;

  /**
   * Country
   */
  @autoserializeAs(() => Country) country?: Country;

  /**
   * Country
   */
  @autoserializeAs(() => Boolean) defaultAddress: boolean;

  /**
   * UserAddress constructor
   */
  constructor(
    street?: string,
    homeType?: string,
    postalCode?: string,
    city?: string,
    district?: Province,
    country?: Country,
    defaultAddress = false
  ) {
    this.street = street;
    this.homeType = homeType;
    this.postalCode = postalCode;
    this.city = city;
    this.district = district;
    this.country = country;
    this.defaultAddress = defaultAddress;
  }
}

/**
 * Class that represents a user list response
 */
export class UserAddressList extends RestItemList<UserAddress> {
  /**
   * User address list
   */
  @autoserializeAsArray(() => UserAddress) items: UserAddress[];

  /**
   * Number of entries in list
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: UserAddress[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
