/**
 * Helper class for creating list of items that comes from the backend
 */
export abstract class RestItemList<T> {
  /**
   * An array of items
   */
  abstract items: T[];

  /**
   * Number of items
   */
  abstract total: number;
}
