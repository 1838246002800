import { autoserializeAs, autoserializeAsArray, inheritSerialization } from 'dcerialize';

import { HelpCenterTypes, TicketsStatusTypes } from '../definitions/help-center.enum';
import { RestItemList } from './rest-item';
import { TableFilter } from './table-filter';

export class Ticket {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id?: number;

  /**
   * Enrolment Id
   */
  @autoserializeAs(() => String) enrolmentId: string;

  /**
   * Course name
   */
  @autoserializeAs(() => String) courseName?: string;

  /**
   * Course institution name
   */
  @autoserializeAs(() => String) courseInstitutionName?: string;

  /**
   * Thread id
   */
  @autoserializeAs(() => Number) ticketId: number;

  /**
   * innotutorUserId
   */
  @autoserializeAs(() => Number) innotutorUserId?: number;

  /**
   * subject
   */
  @autoserializeAs(() => String) subject?: string;
  /**
   * tutor name
   */
  @autoserializeAs(() => String) tutor?: string;

  /**
   * state
   */
  @autoserializeAs(() => Number) state?: number;

  /**
   * category
   */
  @autoserializeAs(() => String) category?: string;

  /**
   * observations
   */
  @autoserializeAs(() => String) observations?: string;

  /**
   * Ticket date
   */
  @autoserializeAs(() => Date) date?: Date;

  /**
   * communications
   */
  @autoserializeAsArray(() => Communication) communications: Communication[];

  constructor(
    enrolmentId: string,
    ticketId: number,
    courseName?: string,
    courseInstitutionName?: string,
    innotutorUserId?: number,
    subject?: string,
    state?: number,
    tutor?: string,
    category?: string,
    observations?: string,
    date?: Date,
    communications = []
  ) {
    this.enrolmentId = enrolmentId;
    this.courseName = courseName;
    this.courseInstitutionName = courseInstitutionName;
    this.ticketId = ticketId;
    this.innotutorUserId = innotutorUserId;
    this.subject = subject;
    this.tutor = tutor;
    this.state = state;
    this.date = date;
    this.category = category;
    this.observations = observations;
    this.communications = communications;
  }

  hasUnread(): boolean {
    const found = this.communications.find((element) => {
      return element.unread === true;
    });

    return !!found;
  }

  isResolved(): boolean {
    return this.state === TicketsStatusTypes.Resolved;
  }
}

/**
 * Class that represents a TutorshipWithCommunications list response
 */
export class TicketList extends RestItemList<Ticket> {
  /**
   * TutorshipWithCommunications list
   */
  @autoserializeAsArray(() => Ticket) items: Ticket[];

  /**
   * Number of TutorshipWithCommunications
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Ticket[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}

export class Communication {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id?: number;

  /**
   * communicationId
   */
  @autoserializeAs(() => Number) communicationId!: number;

  /**
   * Thread id
   */
  @autoserializeAs(() => Number) ticketId?: number;

  /**
   * Communication date
   */
  @autoserializeAs(() => Date) date: Date;

  /**
   * category
   */
  @autoserializeAs(() => String) category?: string;

  /**
   * direction
   */
  @autoserializeAs(() => Number) direction: number;

  /**
   * text
   */
  @autoserializeAs(() => String) text: string;

  /**
   * type
   */
  @autoserializeAs(() => String) type?: string;

  /**
   * Unread
   */
  @autoserializeAs(() => Boolean) unread?: boolean;

  constructor(
    communicationId: number,
    text: string,
    date: Date,
    direction: number,
    ticketId?: number,
    category?: string,
    unread?: boolean,
    type?: string
  ) {
    this.communicationId = communicationId;
    this.ticketId = ticketId;
    this.category = category;
    this.direction = direction;
    this.text = text;
    this.type = type;
    this.date = date;
    this.unread = unread;
  }
}

export class CommunicationDataUpdate {
  /**
   * Unread
   */
  @autoserializeAs(() => Boolean) unread?: boolean;

  constructor(unread?: boolean) {
    this.unread = unread;
  }
}

export class TicketFilter {
  /**
   * Category
   */
  @autoserializeAsArray(() => String) category?: HelpCenterTypes[];

  @autoserializeAs(() => String) enrolmentId?: string;

  constructor(category?: Array<HelpCenterTypes>, enrolmentId?: string) {
    this.category = category;
    this.enrolmentId = enrolmentId;
  }
}

/**
 * Class to serialize the object filter as TickerFilter
 */
@inheritSerialization(() => TableFilter)
export class TicketParametersFilter extends TableFilter<TicketFilter> {
  /**
   * TicketFilter to get required data
   */
  @autoserializeAs(() => TicketFilter) filter: TicketFilter;

  constructor(filter: TicketFilter = new TicketFilter(), query = '', limit = -1, page = 1, sort = '') {
    super(limit, page, query, sort);
    this.filter = filter;
  }
}
