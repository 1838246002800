import { Injectable } from '@angular/core';

import {
  educaFrontDomains,
  educaopenFrontDomains,
  edusportFrontDomains,
  enaldeFrontDomains,
  esibeFrontDomains,
  euroinnovaFrontDomains,
  ineafFrontDomains,
  inesaludFrontDomains,
  inesemFrontDomains,
  innovaFrontDomains,
  rededucaFrontDomains,
  universityFrontDomains
} from '../definitions/vertical-front-domains';
import {
  CustomerConfInterface,
  educaConf,
  educaopenConf,
  edusportConf,
  enaldeConf,
  esibeConf,
  euroinnovaConf,
  ineafConf,
  inesaludConf,
  inesemConf,
  innovaConf,
  rededucaConf,
  universityConf
} from '../environments/customer.conf';

@Injectable({
  providedIn: 'root'
})
export class DomainConfigService {
  domainToConfigMap: { domains: string[]; config: CustomerConfInterface }[] = [
    { domains: euroinnovaFrontDomains, config: euroinnovaConf },
    { domains: inesaludFrontDomains, config: inesaludConf },
    { domains: inesemFrontDomains, config: inesemConf },
    { domains: educaFrontDomains, config: educaConf },
    { domains: edusportFrontDomains, config: edusportConf },
    { domains: enaldeFrontDomains, config: enaldeConf },
    { domains: esibeFrontDomains, config: esibeConf },
    { domains: ineafFrontDomains, config: ineafConf },
    { domains: innovaFrontDomains, config: innovaConf },
    { domains: rededucaFrontDomains, config: rededucaConf },
    { domains: educaopenFrontDomains, config: educaopenConf },
    { domains: universityFrontDomains, config: universityConf }
  ];

  public getConfigForSubdomain(domain: string): CustomerConfInterface {
    for (const mapping of this.domainToConfigMap) {
      if (mapping.domains.includes(domain)) {
        return mapping.config;
      }
    }

    // Return Euroinnova config as a default option
    return euroinnovaConf;
  }
}
