import { autoserializeAs } from 'dcerialize';

/**
 * Class that represents a Price
 */
export class CoursePrice {
  /**
   * Quantity
   */
  @autoserializeAs(() => Number) price: number;
  /**
   * Country
   */
  @autoserializeAs(() => String) country: string;

  /**
   * Currency
   */
  @autoserializeAs(() => String) currency: string;

  constructor(price: number, currency = '', country = '') {
    this.price = price;
    this.currency = currency;
    this.country = country;
  }
}
