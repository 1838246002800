import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { RestItemList } from './rest-item';

export class Country {
  /**
   * Country ID
   */
  @autoserializeAs(() => Number) _id?: number;

  /**
   * Country name
   */
  @autoserializeAs(() => String) text: string;

  constructor(_id?: number, text = '') {
    this._id = _id;
    this.text = text;
  }
}

/**
 * Class that represents a Country list response
 */
export class CountryList extends RestItemList<Country> {
  /**
   * Course list
   */
  @autoserializeAsArray(() => Country) items: Country[];

  /**
   * Number of countries
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Country[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
