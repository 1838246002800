import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { Payment } from './payment';
import { RestItemList } from './rest-item';

export class EnrolmentPayment {
  /**
   * Course name
   */
  @autoserializeAs(() => String) courseName: string;

  /**
   * Enrolment ID
   */
  @autoserializeAs(() => String) enrolmentId: string;

  /**
   * Enrolment ID
   */
  @autoserializeAs(() => Number) billId: number;

  /**
   * Total amount
   */
  @autoserializeAs(() => Number) total: number;

  /**
   * Paid amount
   */
  @autoserializeAs(() => Number) paid: number;

  /**
   * Payments list
   */
  @autoserializeAsArray(() => Payment) payments: Payment[];

  constructor(
    courseName: string,
    enrolmentId: string,
    billId: number,
    total: number,
    paid: number,
    payments: Payment[]
  ) {
    this.courseName = courseName;
    this.enrolmentId = enrolmentId;
    this.billId = billId;
    this.total = total;
    this.paid = paid;
    this.payments = payments;
  }

  public isPaid(): boolean {
    return this.total === this.paid;
  }
}

export class EnrolmentPaymentList extends RestItemList<EnrolmentPayment> {
  /**
   * EnrolmentPayment list
   */
  @autoserializeAsArray(() => EnrolmentPayment) items: EnrolmentPayment[];

  /**
   * Number of items
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: EnrolmentPayment[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}

export class SelectedPayment {
  enrolmentPayment: EnrolmentPayment;
  index: number;

  constructor(enrolmentPayment: EnrolmentPayment, index: number) {
    this.enrolmentPayment = enrolmentPayment;
    this.index = index;
  }

  /**
   * Method to return the enrolmentId
   */
  getEnrolmentId(): string {
    return this.enrolmentPayment.enrolmentId;
  }
}
