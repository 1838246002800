/**
 * Function to retrieve months to be displayed in the date selector
 */
export function getDateMonths(): { [name: string]: string | number }[] {
  return [
    { number: 1, description: 'January' },
    { number: 2, description: 'February' },
    { number: 3, description: 'March' },
    { number: 4, description: 'April' },
    { number: 5, description: 'May' },
    { number: 6, description: 'June' },
    { number: 7, description: 'July' },
    { number: 8, description: 'August' },
    { number: 9, description: 'September' },
    { number: 10, description: 'October' },
    { number: 11, description: 'November' },
    { number: 12, description: 'December' }
  ];
}

/**
 * Function to retrieve days of the week to be displayed in the date selector
 */
export function getDateWeekDays(): { [name: string]: string | number }[] {
  return [
    { number: 1, description: 'Monday' },
    { number: 2, description: 'Tuesday' },
    { number: 3, description: 'Wednesday' },
    { number: 4, description: 'Thursday' },
    { number: 5, description: 'Friday' },
    { number: 6, description: 'Saturday' },
    { number: 7, description: 'Sunday' }
  ];
}
