import { ClassMetaData, PropMetaData, } from "./meta_data";
import { ArrayHandling, isItAnArrayInternal, IsReference, } from "./types";
import { isPrimitiveAnonymousType, setBitConditionally, } from "./utils";
function isInstanceMember(value) {
    if (typeof value === "function") {
        throw new Error("a decorator of dcerialize has been applied to a static member. this is forbidden");
    }
}
export function serializeBitMask(bitMask) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.bitMaskSerialize = bitMask;
    };
}
export function serializeUsing(serializer, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedType = serializer;
        metadata.flags |= 4096;
    };
}
export function serializeAs(type, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedType = type;
        metadata.flags |= 16384;
        metadata.flags = setBitConditionally(metadata.flags, 4, isItAnArrayInternal(type) ? false : isPrimitiveAnonymousType(type));
    };
}
export function serializeAsArray(elementType, arrayConstructor, keyName) {
    return (target, actualKeyName) => {
        var _a;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedKeyType = elementType;
        metadata.serializedType = (_a = arrayConstructor) !== null && _a !== void 0 ? _a : (() => Array);
        metadata.flags |= 16;
        metadata.flags = setBitConditionally(metadata.flags, 4, isItAnArrayInternal(elementType) ? false : isPrimitiveAnonymousType(elementType));
    };
}
export function serializeAsObjectMap(keyType, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedType = keyType;
        metadata.flags |= 64;
        metadata.flags = setBitConditionally(metadata.flags, 4, isItAnArrayInternal(keyType) ? false : isPrimitiveAnonymousType(keyType));
    };
}
export function serializeAsSet(valueType, setConstructor, keyName) {
    return (target, actualKeyName) => {
        var _a;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedKeyType = valueType;
        metadata.serializedType = (_a = setConstructor) !== null && _a !== void 0 ? _a : (() => Set);
        metadata.flags |= 131072;
        metadata.flags = setBitConditionally(metadata.flags, 4, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function serializeAsMap(keyType, valueType, mapConstructor, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey = keyName ? keyName : actualKeyName;
        metadata.serializedType = mapConstructor ? mapConstructor : () => Map;
        metadata.serializedKeyType = keyType;
        metadata.serializedValueType = valueType;
        metadata.flags |= 65536;
        metadata.flags = setBitConditionally(metadata.flags, 4, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function serializeAsJson(keyNameOrTransformKeys, transformKeys = true) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.serializedKey =
            typeof keyNameOrTransformKeys === "string"
                ? keyNameOrTransformKeys
                : actualKeyName;
        metadata.flags |= 256;
        const shouldTransformKeys = typeof keyNameOrTransformKeys === "boolean"
            ? keyNameOrTransformKeys
            : transformKeys;
        metadata.flags = setBitConditionally(metadata.flags, 1024, shouldTransformKeys);
    };
}
export function deserializeUsing(serializer, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyName ? keyName : actualKeyName;
        metadata.deserializedType = serializer;
        metadata.flags |= 2048;
    };
}
export function deserializeAs(type, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyName ? keyName : actualKeyName;
        metadata.deserializedType = type;
        metadata.flags |= 8192;
        metadata.flags = setBitConditionally(metadata.flags, 2, isItAnArrayInternal(type) ? false : isPrimitiveAnonymousType(type));
    };
}
export function deserializeAsArray(elementType, arrayConstructor, keyName, handling = ArrayHandling.Into) {
    return (target, actualKeyName) => {
        var _a;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyName ? keyName : actualKeyName;
        metadata.deserializedKeyType = elementType;
        metadata.deserializedType = (_a = arrayConstructor) !== null && _a !== void 0 ? _a : (() => Array);
        metadata.arrayHandling = handling;
        metadata.flags |= 8;
        metadata.flags = setBitConditionally(metadata.flags, 2, isItAnArrayInternal(elementType) ? false : isPrimitiveAnonymousType(elementType));
    };
}
export function deserializeAsSet(valueType, setConstructor, keyName) {
    return (target, actualKeyName) => {
        var _a;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyName ? keyName : actualKeyName;
        metadata.deserializedKeyType = valueType;
        metadata.deserializedType = (_a = setConstructor) !== null && _a !== void 0 ? _a : (() => Set);
        metadata.flags |= 262144;
        metadata.flags = setBitConditionally(metadata.flags, 2, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function deserializeAsMap(keyType, valueType, mapConstructor, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyName ? keyName : actualKeyName;
        metadata.deserializedType = mapConstructor ? mapConstructor : () => Map;
        metadata.deserializedKeyType = keyType;
        metadata.deserializedValueType = valueType;
        metadata.flags |= 32768;
        metadata.flags = setBitConditionally(metadata.flags, 2, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function deserializeAsObjectMap(valueType, keyType) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey = keyType ? keyType : actualKeyName;
        metadata.deserializedType = valueType;
        metadata.flags |= 32;
        metadata.flags = setBitConditionally(metadata.flags, 2, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function deserializeAsJson(keyNameOrTransformKeys, transformKeys = true) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.deserializedKey =
            typeof keyNameOrTransformKeys === "string"
                ? keyNameOrTransformKeys
                : actualKeyName;
        metadata.flags |= 128;
        const shouldTransformKeys = typeof keyNameOrTransformKeys === "boolean"
            ? keyNameOrTransformKeys
            : transformKeys;
        metadata.flags = setBitConditionally(metadata.flags, 512, shouldTransformKeys);
    };
}
export function autoserializeUsing(serializer, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.serializedKey = key;
        metadata.deserializedKey = key;
        metadata.serializedType = serializer.Serialize;
        metadata.deserializedType = serializer.Deserialize;
        metadata.flags |= 6144;
    };
}
export function autoserializeAs(type, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.deserializedType = type;
        metadata.serializedType = type;
        metadata.flags |=
            16384 | 8192;
        metadata.flags = setBitConditionally(metadata.flags, 6, isItAnArrayInternal(type) ? false : isPrimitiveAnonymousType(type));
    };
}
export function autoserializeAsArray(elementType, arrayConstructor, keyName, handling = ArrayHandling.Into) {
    return (target, actualKeyName) => {
        var _a, _b;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.deserializedKeyType = elementType;
        metadata.serializedKeyType = elementType;
        metadata.deserializedType = (_a = arrayConstructor) !== null && _a !== void 0 ? _a : (() => Array);
        metadata.serializedType = (_b = arrayConstructor) !== null && _b !== void 0 ? _b : (() => Array);
        metadata.arrayHandling = handling;
        metadata.flags |=
            16 | 8;
        metadata.flags = setBitConditionally(metadata.flags, 6, isItAnArrayInternal(elementType) ? false : isPrimitiveAnonymousType(elementType));
    };
}
export function autoserializeAsSet(valueType, setConstructor, keyName) {
    return (target, actualKeyName) => {
        var _a, _b;
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.deserializedKeyType = valueType;
        metadata.serializedKeyType = valueType;
        metadata.deserializedType = (_a = setConstructor) !== null && _a !== void 0 ? _a : (() => Set);
        metadata.serializedType = (_b = setConstructor) !== null && _b !== void 0 ? _b : (() => Set);
        metadata.flags |=
            131072 | 262144;
        metadata.flags = setBitConditionally(metadata.flags, 6, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function autoserializeAsObjectMap(valueType, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.deserializedType = valueType;
        metadata.serializedType = valueType;
        metadata.flags |=
            64 | 32;
        metadata.flags = setBitConditionally(metadata.flags, 6, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function autoserializeAsMap(keyType, valueType, mapConstructor, keyName) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = keyName ? keyName : actualKeyName;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.deserializedType = mapConstructor ? mapConstructor : () => Map;
        metadata.serializedType = mapConstructor ? mapConstructor : () => Map;
        metadata.serializedKeyType = keyType;
        metadata.serializedValueType = valueType;
        metadata.deserializedKeyType = keyType;
        metadata.deserializedValueType = valueType;
        metadata.flags |=
            65536 | 32768;
        metadata.flags = setBitConditionally(metadata.flags, 6, isItAnArrayInternal(valueType) ? false : isPrimitiveAnonymousType(valueType));
    };
}
export function autoserializeAsJson(keyNameOrTransformKeys, transformKeys = true) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        const key = typeof keyNameOrTransformKeys === "string"
            ? keyNameOrTransformKeys
            : actualKeyName;
        const shouldTransformKeys = typeof keyNameOrTransformKeys === "boolean"
            ? keyNameOrTransformKeys
            : transformKeys;
        metadata.deserializedKey = key;
        metadata.serializedKey = key;
        metadata.flags |=
            256 | 128;
        metadata.flags = setBitConditionally(metadata.flags, 1536, shouldTransformKeys);
    };
}
export function inheritSerialization(parentType) {
    return (childType) => {
        if (parentType() === undefined) {
            throw new Error("@inheritSerialization called with undefined argument");
        }
        PropMetaData.inheritMetaData(parentType(), childType);
    };
}
export function emitDefaultValue(DefaultValue) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.emitDefaultValue = DefaultValue;
    };
}
export function onDeserialized(target, actualKeyName) {
    isInstanceMember(target);
    const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
    metadata.flags = 524288;
}
export function defaultValue(instance) {
    return (target, actualKeyName) => {
        isInstanceMember(target);
        const metadata = PropMetaData.getMetaData(target.constructor, actualKeyName);
        metadata.defaultValue = instance;
    };
}
export function isReference(isRef) {
    return (classType) => {
        const metadata = ClassMetaData.getMetaDataOrDefault(classType);
        metadata.isReference = isRef ? IsReference.True : IsReference.False;
    };
}
