export const commonEnv = {
  userDataStorage: 'userData',
  userEnrolmentStorage: 'userEnrolments',
  userCourseBookmarksStorage: 'userCourseBookmarks',
  userArticleBookmarksStorage: 'userArticleBookmarksStorage',
  userWebinarEnrolmentStorage: 'userWebinarEnrolmentStorage',
  accessTokenStorage: 'accessToken',
  accessRefreshTokenStorage: 'accessRefreshToken',
  loginMethodStorage: 'loginMethod',
  currentMoodleCourseInfoStorage: 'currentMoodleCourseInfo',
  currentWebinarInfoStorage: 'currentWebinarInfoStorage',
  currentUserLanguageStorage: 'currentLanguage',
  needSubscriptionStorage: 'needSubscription',
  idSimoToEnrollStorage: 'idSimoToEnroll',
  supportModeStorage: 'supportMode',
  enabledModulesStorage: 'customerModules'
};
