function lousyGet(dic, key) {
    const res = dic.get(key);
    if (res === undefined) {
        throw new Error(`The dictionary doesn't have the key ${key.toString()}`);
    }
    else {
        return res;
    }
}
export class TypeStringDictionary {
    constructor() {
        this.runtimeTyping = false;
        this.init();
    }
    setTypeString(t, s) {
        this.type2string.set(t, s);
        this.string2type.set(s, t);
    }
    getStringFromType(constructor) {
        return lousyGet(this.type2string, constructor);
    }
    hasStringFromType(constructor) {
        return this.type2string.has(constructor);
    }
    getTypeFromString(s) {
        return lousyGet(this.string2type, s);
    }
    hasTypeFromString(s) {
        return this.string2type.has(s);
    }
    resetDictionary() {
        this.init();
    }
    setRuntimeTyping(rtt) {
        this.runtimeTyping = rtt;
    }
    getRuntimeTyping() {
        return this.runtimeTyping;
    }
    init() {
        this.type2string = new Map();
        this.string2type = new Map();
    }
}
export const TypeString = new TypeStringDictionary();
export function RuntimeTypingResetDictionary() {
    TypeString.resetDictionary();
}
export function RuntimeTypingSetTypeString(t, s) {
    TypeString.setTypeString(t, s);
}
export function RuntimeTypingEnable() {
    TypeString.setRuntimeTyping(true);
}
export function RuntimeTypingDisable() {
    TypeString.setRuntimeTyping(false);
}
export function typeString(type) {
    return (classType) => {
        TypeString.setTypeString(classType, type);
    };
}
