import { autoserializeAs } from 'dcerialize';

/**
 * Class that represents a document URL
 */
export class DocumentUrl {
  /**
   * Document URL
   */
  @autoserializeAs(() => String) documentUrl?: string;
}
