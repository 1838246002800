import { cleanCycleBreaking, } from "./cycle";
import { DeserializeArrayInternal, DeserializeInternal, DeserializeJSONInternal, DeserializeMapInternal, DeserializeObjectMapInternal, } from "./deserialize";
import { ClassMetaData, PropMetaData, } from "./meta_data";
import { getRefHandler, } from "./ref";
import { SerializeArrayInternal, SerializeInternal, SerializeJSONInternal, SerializeMapInternal, SerializeObjectMapInternal, SerializePrimitiveInternal, SerializeSetInternal, } from "./serialize";
import { NoOp, } from "./string_transforms";
import { ArrayHandling, InstantiationMethod, ItIsAnArrayInternal, } from "./types";
import { deserializationContinuation, serializationContinuation, } from "./utils";
export { RuntimeTypingResetDictionary, RuntimeTypingSetTypeString, RuntimeTypingEnable, RuntimeTypingDisable, typeString } from "./runtime_typing";
export { setRefHandler, RefHandler } from "./ref";
export * from "./serialize";
export * from "./deserialize";
export * from "./decorators";
export * from "./string_transforms";
export { InstantiationMethod, ArrayHandling } from "./types";
export { DeserializationOccurring, parseNumber, stringifyNumber, SerializationOccurring } from "./utils";
export function RefClean() {
    const handler = getRefHandler();
    if (handler.clean) {
        handler.clean();
    }
    cleanCycleBreaking();
}
export function SetSerializeKeyTransform(fn) {
    PropMetaData.serializeKeyTransform = typeof fn === "function" ? fn : NoOp;
}
export function SetDeserializeKeyTransform(fn) {
    PropMetaData.deserializeKeyTransform = typeof fn === "function" ? fn : NoOp;
}
export function SetDefaultInstantiationMethod(instantiationMethod) {
    PropMetaData.deserializeInstantiationMethod =
        instantiationMethod === null
            ? InstantiationMethod.New
            : instantiationMethod;
}
export function RefCycleDetectionEnable() {
    ClassMetaData.refCycleDetection = true;
}
export function RefCycleDetectionDisable() {
    ClassMetaData.refCycleDetection = false;
}
export function itIsAnArray(arrayElementType, arrayConstructor) {
    return new ItIsAnArrayInternal(arrayElementType, arrayConstructor);
}
export function Serialize(source, type) {
    return serializationContinuation(SerializeInternal, source, type);
}
export function SerializeJSON(sourceJSON, transformKeys = true) {
    return serializationContinuation(SerializeJSONInternal, sourceJSON, transformKeys);
}
export function SerializePrimitive(primitiveSource, type) {
    return serializationContinuation(SerializePrimitiveInternal, primitiveSource, type);
}
export function SerializeSet(setSource, setElementType) {
    return serializationContinuation(SerializeSetInternal, setSource, setElementType);
}
export function SerializeArray(sourceArray, arrayElementType) {
    return serializationContinuation(SerializeArrayInternal, sourceArray, arrayElementType);
}
export function SerializeMap(sourceMap, keyType, valueType) {
    return serializationContinuation(SerializeMapInternal, sourceMap, keyType, valueType);
}
export function SerializeObjectMap(objectMapSource, valueType) {
    return serializationContinuation(SerializeObjectMapInternal, objectMapSource, valueType);
}
export function DeserializeObjectMap(data, valueType, targetObjectMap, instantiationMethod = PropMetaData.deserializeInstantiationMethod) {
    return deserializationContinuation(DeserializeObjectMapInternal, data, valueType, targetObjectMap, instantiationMethod);
}
export function DeserializeMap(data, keyType, valueType, targetMap, instantiationMethod = PropMetaData.deserializeInstantiationMethod) {
    return deserializationContinuation(DeserializeMapInternal, data, keyType, valueType, () => Map, targetMap, instantiationMethod);
}
export function DeserializeArray(data, arrayType, handling = ArrayHandling.Into, targetArray, instantiationMethod = PropMetaData.deserializeInstantiationMethod) {
    return deserializationContinuation(DeserializeArrayInternal, data, arrayType, () => Array, handling, targetArray, instantiationMethod);
}
export function DeserializeJSON(data, transformKeys = true, target) {
    return deserializationContinuation(DeserializeJSONInternal, data, transformKeys, target);
}
export function Deserialize(data, type, target, instantiationMethod = PropMetaData.deserializeInstantiationMethod) {
    return deserializationContinuation(DeserializeInternal, data, type, target, instantiationMethod);
}
