import { autoserializeAs, autoserializeAsArray } from 'dcerialize';
import { EnrolmentSource, EnrolmentStatus } from 'src/utils/enums';

import { CourseReduced } from './course';
import { RestItemList } from './rest-item';

/**
 * Enrolment finalized statuses
 */
export const finishedStatuses = [EnrolmentStatus.Finalized, EnrolmentStatus.Convalidated];

/**
 * Enrolment in progress statuses
 */
export const inProgressStatuses = [
  EnrolmentStatus.Active,
  EnrolmentStatus.Prorogued,
  EnrolmentStatus.ExtensionOfTime,
  EnrolmentStatus.Interrupted
];
export class EnrolmentWithCourse {
  /**
   * _id
   */
  @autoserializeAs(() => Number) _id: number;

  /**
   * User ID
   */
  @autoserializeAs(() => Number) userId: number;

  /**
   * Course ID
   */
  @autoserializeAs(() => Number) courseId: number;

  /**
   * Enrolment ID
   */
  @autoserializeAs(() => String) enrolmentId: string;

  /**
   * User's progress in the course
   */
  @autoserializeAs(() => Number) progress: number;

  /**
   * Last access to the course
   */
  @autoserializeAs(() => Date) lastAccess: Date;

  /**
   * Date of enrollment
   */
  @autoserializeAs(() => Date) registeredAt?: Date;

  /**
   * Course information
   */
  @autoserializeAs(() => CourseReduced) course: CourseReduced;

  /**
   * Enrolment status
   */
  @autoserializeAs(() => String) status: EnrolmentStatus;

  /**
   * User rating
   */
  @autoserializeAs(() => Number) userRating?: number;

  /**
   * Campus url given by legacy enrolments
   */
  @autoserializeAs(() => String) campusUrl?: string;

  /**
   * Campus cif
   */
  @autoserializeAs(() => String) cif!: string;

  /**
   * Enrolment source
   */
  @autoserializeAs(() => String) source: EnrolmentSource;

  /**
   * Parent enrolment ID
   */
  @autoserializeAs(() => String) parentEnrolmentId?: string;

  constructor(
    userId: number,
    courseId: number,
    progress: number,
    lastAccess: Date,
    registeredAt: Date,
    course: CourseReduced,
    status: EnrolmentStatus,
    _id: number,
    enrolmentId: string,
    cif: string,
    source: EnrolmentSource,
    userRating?: number,
    campusUrl?: string,
    parentEnrolmentId?: string
  ) {
    this.userId = userId;
    this.courseId = courseId;
    this.progress = progress;
    this.lastAccess = lastAccess;
    this.registeredAt = registeredAt;
    this.course = course;
    this.status = status;
    this._id = _id;
    this.enrolmentId = enrolmentId;
    this.userRating = userRating;
    this.campusUrl = campusUrl;
    this.cif = cif;
    this.source = source;
    this.parentEnrolmentId = parentEnrolmentId;
  }

  /**
   * Method to obtain the enrolment id
   */
  getEnrolmentId(): string {
    return this.enrolmentId;
  }

  /**
   * Whether the enrolment is a legacy one
   */
  isLegacy(): boolean {
    return this.course.idSimo === undefined;
  }
}

export class EnrolmentWithCourseList extends RestItemList<EnrolmentWithCourse> {
  /**
   * EnrolmentWithCourse list
   */
  @autoserializeAsArray(() => EnrolmentWithCourse) items: EnrolmentWithCourse[];

  /**
   * Number of items
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: EnrolmentWithCourse[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}

export class EnrolmentProgress {
  /**
   * Course id
   */
  @autoserializeAs(() => Number) courseId: number;

  /**
   * User's progress in the course
   */
  @autoserializeAs(() => Number) progress: number;

  constructor(courseId: number, progress: number) {
    this.courseId = courseId;
    this.progress = progress;
  }
}

export class EnrolmentProgressList extends RestItemList<EnrolmentProgress> {
  /**
   * EnrolmentProgress list
   */
  @autoserializeAsArray(() => EnrolmentProgress) items: EnrolmentProgress[];

  /**
   * Number of items
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: EnrolmentProgress[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
