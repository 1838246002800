import { autoserializeAs } from 'dcerialize';

/**
 * Login response structure
 */
export class LoginResponse {
  /**
   * If the login was successful or not
   */
  @autoserializeAs(() => Boolean, 'login_ok') loginOk?: boolean;

  /**
   * Login access token
   */
  @autoserializeAs(() => String, 'access_token') accessToken?: string;
  /**
   * Login refresh access token
   */
  @autoserializeAs(() => String, 'refresh_token') refreshToken?: string;

  /**
   * If the user is blocked or not
   */
  @autoserializeAs(() => Boolean, 'blocked') blocked?: boolean;
}
