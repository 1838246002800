import { getRefHandler, } from "./ref";
import { InstantiationMethod, } from "./types";
export function getTarget(type, target, instantiationMethod) {
    if (target !== null && target !== void 0) {
        return target;
    }
    if (type !== null) {
        switch (instantiationMethod) {
            case InstantiationMethod.New:
                return new type();
            case InstantiationMethod.ObjectCreate:
                return Object.create(type.prototype);
        }
    }
    return {};
}
export function isPrimitiveType(type) {
    return (type === String ||
        type === Boolean ||
        type === Number ||
        type === Date ||
        type === RegExp);
}
export function isPrimitiveAnonymousType(type) {
    try {
        return (type() === String ||
            type() === Boolean ||
            type() === Number ||
            type() === Date ||
            type() === RegExp);
    }
    catch (error) {
        return false;
    }
}
export function DowncastPrimitive(value) {
    if (value instanceof String
        || value instanceof Boolean
        || value instanceof Number
        || value instanceof RegExp
        || value instanceof Date) {
        return value.valueOf();
    }
    throw new Error("DowncastPrimitive failed on " + value);
}
export function setBitConditionally(value, bits, condition) {
    if (condition) {
        return value | bits;
    }
    else {
        return value & ~bits;
    }
}
export function parseNumber(key, value) {
    switch (value) {
        case "NaN":
            return Number.NaN;
        case "Infinity":
            return Number.POSITIVE_INFINITY;
        case "-Infinity":
            return Number.NEGATIVE_INFINITY;
        default:
            return value;
    }
}
export function stringifyNumber(key, value) {
    if (typeof value === "number") {
        if (Number.isNaN(value) || isNaN(value)) {
            return "NaN";
        }
        else if (!isFinite(value)) {
            return value < 0 ? "-Infinity" : "Infinity";
        }
    }
    return value;
}
let NbDeserialization = 0;
let NbSerialization = 0;
export function serializationContinuation(func, ...args) {
    let ret;
    try {
        NbSerialization++;
        const handler = getRefHandler();
        if (handler.init) {
            handler.init();
        }
        ret = func(...args);
        if (handler.done) {
            handler.done();
        }
    }
    finally {
        NbSerialization--;
    }
    return ret;
}
export function deserializationContinuation(func, ...args) {
    let ret;
    try {
        NbDeserialization++;
        const handler = getRefHandler();
        if (handler.init) {
            handler.init();
        }
        ret = func(...args);
        if (handler.done) {
            handler.done();
        }
    }
    finally {
        NbDeserialization--;
    }
    return ret;
}
export function DeserializationOccurring() {
    return NbDeserialization > 0;
}
export function SerializationOccurring() {
    return NbSerialization > 0;
}
