import { ClassMetaData, getDefaultValue, PropMetaData, } from "./meta_data";
import { getRefHandler, } from "./ref";
import { TypeString, } from "./runtime_typing";
import { ArrayHandling, InstantiationMethod, isItAnArrayInternal, IsReference, } from "./types";
import { getTarget, isPrimitiveType, } from "./utils";
const keywords = ["$type"];
function notAKeyword(y) {
    return Array.from(getRefHandler().keyWord).concat(keywords).find((x) => x === y) === undefined;
}
export function DeserializeObjectMapInternal(data, valueType, target, instantiationMethod = InstantiationMethod.New) {
    if (data === null) {
        return null;
    }
    function isJsonObject(variable) {
        return typeof variable === "object";
    }
    if (!isJsonObject(data)) {
        throw new Error("Expected input to be of type `object` but received: " + typeof data);
    }
    if (target === null || target === undefined) {
        target = {};
    }
    const isReference = ClassMetaData.getMetaDataOrDefault(target.constructor).isReference;
    if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
        isReference === IsReference.True) {
        const refObject = getRefHandler().deserializationGetObject(data);
        if (refObject !== undefined) {
            return refObject;
        }
    }
    const keys = Object.keys(data).filter(notAKeyword);
    for (const key of keys) {
        const value = data[key];
        if (value !== undefined) {
            target[PropMetaData.deserializeKeyTransform(key)] = DeserializeInternal(value, valueType, target[key], instantiationMethod);
        }
    }
    return target;
}
export function DeserializeMapInternal(data, keyType, valueType, mapConstructor, target, instantiationMethod) {
    if (typeof data !== "object") {
        throw new Error("Expected input to be of type `object` but received: " + typeof data);
    }
    if (target === null || target === undefined) {
        target = new (mapConstructor())();
    }
    if (data === null) {
        return null;
    }
    const isReference = ClassMetaData.getMetaDataOrDefault(target.constructor).isReference;
    if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
        isReference === IsReference.True) {
        const refObject = getRefHandler().deserializationGetObject(data);
        if (refObject !== undefined) {
            return refObject;
        }
        getRefHandler().deserializationRegisterObject(data, target);
    }
    const keys = Object.keys(data).filter(notAKeyword);
    for (const key of keys) {
        const value = data[key];
        if (value !== undefined) {
            if (isItAnArrayInternal(keyType)) {
                throw new Error("a key can not be an array");
            }
            const keyTypeF = keyType();
            const isString = keyTypeF === String;
            if (keyTypeF !== String && keyTypeF !== Number) {
                throw new Error("a key must be a primitive type");
            }
            const keyName = keyTypeF(isString ?
                PropMetaData.deserializeKeyTransform(key) :
                key);
            target.set(keyName, DeserializeInternal(data[key], valueType, target.get(keyName), instantiationMethod));
        }
    }
    return target;
}
export function DeserializeArrayInternal(data, type, arrayConstructor, handling, target, instantiationMethod) {
    if (data === null) {
        return null;
    }
    if (!Array.isArray(data)) {
        throw new Error("Expected input to be an array but received: " + typeof data);
    }
    if (!Array.isArray(target)) {
        target = new (arrayConstructor())();
    }
    let offset;
    switch (handling) {
        case ArrayHandling.Into:
            offset = 0;
            target.length = data.length;
            break;
        case ArrayHandling.New:
            offset = 0;
            target.length = 0;
            break;
        case ArrayHandling.ConcatAtTheEnd:
            offset = target.length;
            break;
    }
    for (let i = 0; i < data.length; i++) {
        target[offset + i] = DeserializeInternal(data[i], type, target[offset + i], instantiationMethod);
    }
    return target;
}
export function DeserializeSetInternal(data, keyType, setConstructor, target, instantiationMethod) {
    if (data === null) {
        return null;
    }
    if (!Array.isArray(data)) {
        throw new Error("Expected input to be an array but received: " + typeof data);
    }
    if (!(target instanceof Set)) {
        target = new (setConstructor())();
    }
    for (const d of data) {
        target.add(DeserializeInternal(d, keyType, undefined, instantiationMethod));
    }
    return target;
}
export function DeserializeSet(data, keyType, setConstructor = (() => Set), target, instantiationMethod = PropMetaData.deserializeInstantiationMethod) {
    if (data === null) {
        return null;
    }
    return DeserializeSetInternal(data, keyType, setConstructor, target, instantiationMethod);
}
export function DeserializePrimitive(data, type, target) {
    if (data === null) {
        return null;
    }
    else if (type() === Date) {
        const deserializedDate = new Date(data);
        if (target instanceof Date) {
            target.setTime(deserializedDate.getTime());
            return target;
        }
        else {
            return deserializedDate;
        }
    }
    else if (type() === RegExp) {
        const fragments = /\/(.*?)\/([gimy])?$/.exec(data);
        return new RegExp(fragments[1], fragments[2] || "");
    }
    else {
        return (type())(data);
    }
}
export function DeserializeJSONInternal(data, transformKeys = true, target) {
    target = {};
    if (Array.isArray(data)) {
        if (!Array.isArray(target)) {
            target = new Array(data.length);
        }
        target.length = data.length;
        for (let i = 0; i < data.length; i++) {
            target[i] = DeserializeJSONInternal(data[i], transformKeys, target[i]);
        }
        return target;
    }
    const type = typeof data;
    if (type === "object") {
        const returnValue = (target && typeof target === "object"
            ? target
            : {});
        const keys = Object.keys(data);
        for (const key of keys) {
            const value = data[key];
            if (value !== undefined) {
                const returnValueKey = transformKeys
                    ? PropMetaData.deserializeKeyTransform(key)
                    : key;
                returnValue[returnValueKey] = DeserializeJSONInternal(data[key], transformKeys);
            }
        }
        return returnValue;
    }
    else if (type === "function") {
        throw new Error("Cannot deserialize a function, input is not a valid json object");
    }
    return data;
}
export function DeserializeInternal(data, type, target, instantiationMethod = InstantiationMethod.New) {
    if (data === null) {
        return null;
    }
    if (isItAnArrayInternal(type)) {
        target = DeserializeArrayInternal(data, type.type, type.ctor, type.handling, target, instantiationMethod);
    }
    else {
        if (TypeString.getRuntimeTyping() && !isPrimitiveType(type()) && data.$type) {
            type = () => TypeString.getTypeFromString(data.$type);
        }
        let classType;
        if (type()) {
            classType = type();
        }
        else if (target === null || target === void 0 ? void 0 : target.constructor) {
            classType = target.constructor;
        }
        else {
            throw new Error("Can not guess the type of the class deserialized");
        }
        let isReference = ClassMetaData.getMetaDataOrDefault(classType).isReference;
        if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
            isReference === IsReference.True) {
            const refObject = getRefHandler().deserializationGetObject(data);
            if (refObject !== undefined) {
                return refObject;
            }
        }
        const metadataList = PropMetaData.getMetaDataForType(type());
        if (metadataList === null) {
            if (typeof type() === "function") {
                if (isPrimitiveType(type())) {
                    return DeserializePrimitive(data, type, target);
                }
                switch (instantiationMethod) {
                    case InstantiationMethod.New:
                        return new (type())();
                    case InstantiationMethod.ObjectCreate:
                        return Object.create(type().prototype);
                    default:
                        return {};
                }
            }
            return null;
        }
        target = getTarget(type(), target, instantiationMethod);
        isReference = ClassMetaData.getMetaDataOrDefault(classType).isReference;
        if (ClassMetaData.refCycleDetection && isReference !== IsReference.False ||
            isReference === IsReference.True) {
            getRefHandler().deserializationRegisterObject(data, target);
        }
        let onDeserializedCallbackName = null;
        for (const metadata of metadataList) {
            if (metadata.flags === 524288) {
                onDeserializedCallbackName = metadata.keyName;
                continue;
            }
            if (metadata.deserializedKey === null) {
                continue;
            }
            const source = data[metadata.getDeserializedKey()];
            const keyName = metadata.keyName;
            const flags = metadata.flags;
            const defVal = getDefaultValue(metadata, source);
            if (metadata.emitDefaultValue === false && (source === undefined || source === defVal)) {
                target[keyName] = defVal;
                continue;
            }
            if (source === undefined) {
                continue;
            }
            if ((flags & 32) !== 0) {
                target[keyName] = DeserializeObjectMapInternal(source, metadata.deserializedType, target[keyName], instantiationMethod);
            }
            else if ((flags & 32768) !== 0) {
                target[keyName] = DeserializeMapInternal(source, metadata.deserializedKeyType, metadata.deserializedValueType, metadata.deserializedType, target[keyName], instantiationMethod);
            }
            else if ((flags & 8) !== 0) {
                target[keyName] = DeserializeArrayInternal(source, metadata.deserializedKeyType, metadata.deserializedType, metadata.arrayHandling, target[keyName], instantiationMethod);
            }
            else if ((flags & 262144) !== 0) {
                target[keyName] = DeserializeSetInternal(source, metadata.deserializedKeyType, metadata.deserializedType, target[keyName], instantiationMethod);
            }
            else if ((flags & 2) !== 0) {
                target[keyName] = DeserializePrimitive(source, metadata.deserializedType, target[keyName]);
            }
            else if ((flags & 8192) !== 0) {
                target[keyName] = DeserializeInternal(source, metadata.deserializedType, target[keyName], instantiationMethod);
            }
            else if ((flags & 128) !== 0) {
                target[keyName] = DeserializeJSONInternal(source, (flags & 512) !== 0, instantiationMethod);
            }
            else if ((flags & 2048) !== 0) {
                target[keyName] = metadata.deserializedType(source, target[keyName], instantiationMethod);
            }
        }
        if (onDeserializedCallbackName !== null) {
            target[onDeserializedCallbackName](data, target, instantiationMethod);
        }
    }
    return target;
}
