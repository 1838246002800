export function NoOp(str) {
    return str;
}
const STRING_CAMELIZE_REGEXP = /(-|_|\.|\s)+(.)?/g;
const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
const STRING_UNDERSCORE_REGEXP_1 = /([a-z\d])([A-Z]+)/g;
const STRING_UNDERSCORE_REGEXP_2 = /-|\s+/g;
const STRING_DASHERIZE_REGEXP = /([a-z\d])([A-Z])/g;
export function CamelCase(str) {
    return str
        .replace(STRING_CAMELIZE_REGEXP, (match, separator, chr) => {
        return chr ? chr.toUpperCase() : "";
    })
        .replace(/^([A-Z])/, (match) => {
        return match.toLowerCase();
    });
}
export function SnakeCase(str) {
    return str.replace(STRING_DECAMELIZE_REGEXP, "$1_$2").toLowerCase();
}
export function UnderscoreCase(str) {
    return str
        .replace(STRING_UNDERSCORE_REGEXP_1, "$1_$2")
        .replace(STRING_UNDERSCORE_REGEXP_2, "_")
        .toLowerCase();
}
export function DashCase(str) {
    str = str.replace(/_/g, "-");
    return str.replace(STRING_DASHERIZE_REGEXP, "$1-$2").toLowerCase();
}
