import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class CourseModule {
  /**
   * Module name
   */
  @autoserializeAs(() => String) text: string;

  /**
   * Children nodes
   */
  @autoserializeAsArray(() => CourseModule) subsection?: CourseModule[];

  constructor(text: string, subsection: CourseModule[] = []) {
    this.text = text;
    this.subsection = subsection;
  }
}
