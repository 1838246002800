class CycleBreaking {
    constructor() {
        this.seenSet = new WeakSet();
    }
    seen(obj) {
        const res = this.seenSet.has(obj);
        if (!res) {
            this.seenSet.add(obj);
        }
        return res;
    }
}
export let cycleBreaking = new CycleBreaking();
export function cleanCycleBreaking() {
    cycleBreaking = new CycleBreaking();
}
