import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { getDateMonths } from '../utils/date-data';
import { RestItemList } from './rest-item';

export class Article {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id: number;

  /**
   * Article URL
   */
  @autoserializeAs(() => String) url: string;

  /**
   * Article author
   */
  @autoserializeAs(() => String) author: string;

  /**
   * Content
   */
  @autoserializeAs(() => String) content: string;

  /**
   * Article title
   */
  @autoserializeAs(() => String) title: string;

  /**
   * Article image
   */
  @autoserializeAs(() => String) img: string;

  /**
   * Article video
   */
  @autoserializeAs(() => String) video: string;

  /**
   * Article categories
   */
  @autoserializeAsArray(() => Number) categories: number[];

  /**
   * Is the article active?
   */
  @autoserializeAs(() => Boolean) isActive: boolean;

  /**
   * Creation date
   */
  @autoserializeAs(() => Date) createdAt: Date;

  /**
   * Is popular the blog?
   */
  @autoserializeAs(() => Boolean) popular: boolean;

  /**
   * Is highlighted the blog?
   */
  @autoserializeAs(() => Boolean) highlighted: boolean;

  constructor(
    id: number,
    url: string,
    author: string,
    content: string,
    title: string,
    img: string,
    video: string,
    categories: number[],
    isActive: boolean,
    popular: boolean,
    highlighted: boolean,
    createdAt: Date
  ) {
    this._id = id;
    this.url = url;
    this.author = author;
    this.content = content;
    this.title = title;
    this.img = img;
    this.video = video;
    this.categories = categories;
    this.isActive = isActive;
    this.popular = popular;
    this.highlighted = highlighted;
    this.createdAt = createdAt;
  }

  /**
   * @return how many hours since publication, -1 if more than one day after publication
   */
  public hoursSincePublication(): number {
    const secondsAgo = Math.floor((Date.now() - Number(this.createdAt)) / 1000);

    return Math.floor(secondsAgo / 3600);
  }

  /**
   * Returns the month translate with the time since the article was published
   */
  getDateMonthTranslate(): string {
    return getDateMonths()[this.createdAt.getMonth()].description.toString();
  }

  /**
   * Method that checks if one day at least since the publication
   */
  public oneDayAtLeastSincePublication(): boolean {
    return this.hoursSincePublication() >= 24;
  }
}

/**
 * Class that represents an Article list response
 */
export class ArticleList extends RestItemList<Article> {
  /**
   * Article list
   */
  @autoserializeAsArray(() => Article) items: Article[];

  /**
   * Number of articles
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: Article[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}
